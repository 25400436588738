@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container-modal {
    padding: 40px 50px 40px 50px;
}

.txt-modal {
    color: #FFF;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 17px;
    /* letter-spacing: 1.2px; */
    font-weight: 500;
    /* font-weight: bold; */
}

.txt-koort {
    color: #ffc000;
}

.container-preco {
    border: 4px solid #284adc;
    background: #284adc;
    height: 350px;
    width: 100%;
    margin-top: 10px;
}

.preco {
    background: #000;
    height: 200px;
    width: 100%;
}

.lb-preco {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 19px;
    letter-spacing: 1.5px;
    color: #fff;
    margin-top: 50px;
}

.txt-preco {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 40px;
    color: #fff;
    margin-top: 10px;
    font-weight: bold;
}

.container-preco-sugerido {
    float: left;
    width: 100%;
}

.preco-sugerido {
    width: 30%;
    float: left;
    height: 100px;
}
.preco-sugerido:nth-of-type(1) {
    padding-left: 25px;
}


.preco-sugerido:nth-of-type(3) {
    padding-right: 25px;
}

.preco-sugerido:nth-of-type(2) {
    width: 40%;
    border: 1.5px solid #ffc000;
    padding: 10px;
    height: 70px;
    margin-top: 40px;
}

.preco-sugerido:nth-of-type(2)>span {
    color: #fff;
    float: left;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
}

.logo {
    float: left;
    width: 23px;
    margin-right: 5px;
}

.logo-txt {
    float: left;
    width: 130px;
}

.title_box {
    margin-top: 65px;
    border-top: #ffc000 1px solid;
}

.title_box #title {
    position: relative;
    top: -0.9em;
    display: inline;
    color: #FFF;
    padding-right: 5px;
    background-color: #284adc;
}

.title_box_max #title {
    margin-left: 80px;
    padding-left: 5px;
}




.title_box #content {
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    /* float:left; */
    margin-top: -15px;
}

.title_box_max #content {
    text-align: right;
}

.container-txt-check{
    float: left;
    font-size: 15px;
    color: #FFF;
    margin-left: 10px;
    margin-top: -2px;
}

.container-txt-check > b{
    color: #ffc000;
}
.container-txt-check > span{
    font-weight: bold;
}

.container-button{
    width: 100%;
    text-align: right;
    margin-top: 40px;
    align-self: rigth;
    padding-left: 350px;
}


@media (max-width: 768px) {
    .txt-preco{
        font-size: 23px;
    }
    .container-button{
        padding-left: 60px;
    }
    .preco-sugerido:nth-of-type(1) {
        padding-left: 0px;
    }
    .title_box #title {
        font-size: 10px;
        top: -1.5em !important;
    }
    .title_box_max #title{
        margin-left: 29px;
    }
    .title_box #content{
        font-size: 9px;
    }
    .preco-sugerido:nth-of-type(2)>span{
        font-size: 9px;
    }
    .logo{
        margin-left: 20px;
        margin-bottom: 5px;
        margin-top: -5px;
    }
}