.ReactModal__Overlay {
  z-index: 999999999;
}

.ReactModal__Content {
  overflow-y: scroll;
}

select,
input,
textarea,
button {
  border: 0px;
  outline: 0px;
}

.container-contact {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.container__title {
  font-size: 15px;
  order: 1;
  color: #FFF;
  flex-basis: 100%;
  margin-bottom: 5px;
}

.container__field {
  flex-basis: 50%;
  order: 2;
  padding: 5px;
}

.container__field_100 {
  flex-basis: 100%;
  order: 2;
  padding: 5px;
}

.container__field>select {
  width: 100%;
  height: 35px;
  font-size: 15px;
  color: #666;
  background: white;
  padding-left: 10px;
}

.container__field>input[type=text] {
  height: 35px;
  width: 100%;
  background: white;
  color: #666;
  font-size: 15px;
  padding-left: 10px;
}

.container__field>textarea {
  width: 100%;
  background: white;
  color: #666;
  font-size: 15px;
  padding: 10px;
}

.container__field>textarea::placeholder {
  color: #666;
  opacity: 0.5;
}

.container__field>input[type=text]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666;
  opacity: 0.5;
  /* Firefox */
}

.container__button {
  flex-basis: 100%;
  order: 3;
  padding: 5px;
  text-align: right;
  flex-direction: column;
  display: flex;
  margin-top: 16px;
}

.container__button>button {
  background: #8ACC2C;
  color: #FFF;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  padding: 8px;

  transition: filter .2s;
}

.container__button>button:nth-child(2) {
  margin-top: 12px;
  background: transparent;
  opacity: 0.6;
}

.container__button>button:hover {
  filter: brightness(0.9);
}
