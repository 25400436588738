.container-result {
    width: 100%;
    height: 400px;
    /* background: #ccc; */
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #000;
}

.container-result__type {
    width: 100%;
}

.container-result__button {
    float: right;
}

.container-result__button > button{
    padding: 15px 10px;
    background: #dc3545;
    cursor: pointer;
    color: white;
    font-weight: 700;
}

.container-result__type span {
    padding: 5px 10px;
    background: #2B50D8;
    color: white;
    font-weight: bold;
    margin-right: 10px;
}

.container-result__price {
    width: 100%;
    margin-top: 20px;
}

.container-result__price>label {
    font-weight: bold;
}

.container-result__price>h1 {
    font-weight: bold;
    font-size: 30px;
}

.container-result__info>span {
    color: #777;
    font-size: 15px;
    width: 100%;
}

.container-result__info>div {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #999;
}

.container-result__info>div span {
    padding: 5px 10px;
    font-size: 14px;
    color: #777;
}

.container-result__owner {
    width: 100%;
    margin-top: 10px;
}

.container-result__owner>h1 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.container-result__owner>span {
    font-size: 15px;
    color: #777;
    padding-left: 20px;
}

.container-result__msg {
    margin-top: 15px;
}

.container-result__msg h1 {
    font-weight: bold;
    font-size: 18px;
}

.container-result__msg label {
    font-size: 15px;
    color: #777;
}