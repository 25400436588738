.container_congratulations {
    text-align: center;
    padding-top: 30px;
}

.container_congratulations>h1 {
    font-weight: 700;
    font-size: 16px;
}

.container_congratulations>h2 {
    font-weight: 400;
    font-size: 15px;
}

.container_congratulations>h3{
    color: #2B50D8;
    font-size: 16px;
    font-weight: bold;
}