@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.container-result {
    width: 100%;
    height: 400px;
    /* background: #ccc; */
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid #000;
}

.container-result__type {
    width: 100%;
}

.container-result__button {
    float: right;
}

.container-result__button > button{
    padding: 15px 10px;
    background: #dc3545;
    cursor: pointer;
    color: white;
    font-weight: 700;
}

.container-result__type span {
    padding: 5px 10px;
    background: #2B50D8;
    color: white;
    font-weight: bold;
    margin-right: 10px;
}

.container-result__price {
    width: 100%;
    margin-top: 20px;
}

.container-result__price>label {
    font-weight: bold;
}

.container-result__price>h1 {
    font-weight: bold;
    font-size: 30px;
}

.container-result__info>span {
    color: #777;
    font-size: 15px;
    width: 100%;
}

.container-result__info>div {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #999;
}

.container-result__info>div span {
    padding: 5px 10px;
    font-size: 14px;
    color: #777;
}

.container-result__owner {
    width: 100%;
    margin-top: 10px;
}

.container-result__owner>h1 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.container-result__owner>span {
    font-size: 15px;
    color: #777;
    padding-left: 20px;
}

.container-result__msg {
    margin-top: 15px;
}

.container-result__msg h1 {
    font-weight: bold;
    font-size: 18px;
}

.container-result__msg label {
    font-size: 15px;
    color: #777;
}
.bt-filter+label {
  height: 50px;
  padding-top: 7px;
  padding-bottom: 12px;
  padding-left: 7px;
  padding-right: 17px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  background-color: white;
  border: solid 1px #83786f;
  border-radius: 1px;
  position: relative;
  background-position: right 0.3rem center;
  /* line-height: 50px; */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
}

.bt-filter {
  display: none;
}

.bt-filter:checked+label {
  /* border: 1px solid #0d6efd; */
  color: #0d6efd;
}

.color-blue {
  /* border: 1px solid #0d6efd !important; */
  color: #0d6efd;
}

.color-blue>.icon-filter {
  color: #0d6efd;
}

.icon-filter {
  right: 0px;
}

.bt-filter:checked+label>.icon-filter {
  color: #0d6efd;
  transform: rotate(90deg);
}

.bt-filter:checked+label>.container__filter {
  visibility: visible;
}

.container__filter {
  position: absolute;
  top: 52px;
  left: -1px;
  /* border: 1px solid #83786f; */
  min-width: 370px;
  padding: 15px;
  background-color: white;
  /* border: solid 1px #83786f; */
  visibility: hidden;
  z-index: 99;
  box-shadow: 0 4px 5px 4px rgb(0 0 0 / 25%);
}

.container__filter input {
  border: solid 1px #c7c7c7;
  height: 38px;
}

.no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-gutter>[class^="col-"] {
  padding-left: 0 !important;
  padding-right: 5px !important;
}

.container-select-product {
    width: 100%;
    margin-bottom: 15px;
}

.select-product {
    margin-left: calc(50% - 175px);
    width: 350px;
    padding: 15px;
    outline: 0px;
    font-size: 15px;
    border: 1px solid #000;
}

.container-tb {
    width: 100%;
}

.container-tb span {
    font-weight: bold;
    font-size: 14px;
}

.container-tb>table {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb tr {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb>table td {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.container-tb>table tr td:nth-child(2) {
    background: #2b50d8;
    width: 30%;
    text-align: center;
    color: white;
    padding-right: 15px;
}

.container-value {
    width: 30%;
    background: #2b50d8;
    color: white;
    margin-left: 70%;
    height: 170px;
    text-align: center;
    padding-top: 30px;
    margin-top: 50px;
}

.container-value h2 {
    font-size: 25px;
}

.container-value h1 {
    font-size: 40px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .container-value {
        display: none;
    }
}
.koort_form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-gutter>[class^="col-"] {
  padding-left: 0 !important;
  padding-right: 5px !important;
}

.Typist {
  min-height: 102px;
}

.bg-global {
  box-shadow: inset 0px 380px 180px -10px rgb(0 0 0 / 70%);
}

.bg-global::after {
  box-shadow: inset 0px 125px 60px -10px rgb(0 0 0 / 70%);
  content: " ";
  height: 200px;
  width: 100%;
  position: absolute;
}

.ReactModal__Overlay {
  z-index: 999999999;
}

.ReactModal__Content {
  overflow-y: scroll;
}

select,
input,
textarea,
button {
  border: 0px;
  outline: 0px;
}

.container-contact {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.container__title {
  font-size: 15px;
  order: 1;
  color: #FFF;
  flex-basis: 100%;
  margin-bottom: 5px;
}

.container__field {
  flex-basis: 50%;
  order: 2;
  padding: 5px;
}

.container__field_100 {
  flex-basis: 100%;
  order: 2;
  padding: 5px;
}

.container__field>select {
  width: 100%;
  height: 35px;
  font-size: 15px;
  color: #666;
  background: white;
  padding-left: 10px;
}

.container__field>input[type=text] {
  height: 35px;
  width: 100%;
  background: white;
  color: #666;
  font-size: 15px;
  padding-left: 10px;
}

.container__field>textarea {
  width: 100%;
  background: white;
  color: #666;
  font-size: 15px;
  padding: 10px;
}

.container__field>textarea::placeholder {
  color: #666;
  opacity: 0.5;
}

.container__field>input[type=text]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666;
  opacity: 0.5;
  /* Firefox */
}

.container__button {
  flex-basis: 100%;
  order: 3;
  padding: 5px;
  text-align: right;
  flex-direction: column;
  display: flex;
  margin-top: 16px;
}

.container__button>button {
  background: #8ACC2C;
  color: #FFF;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  padding: 8px;

  transition: filter .2s;
}

.container__button>button:nth-child(2) {
  margin-top: 12px;
  background: transparent;
  opacity: 0.6;
}

.container__button>button:hover {
  filter: brightness(0.9);
}

.input-file-profile{
  width: 100%;
  background: #2b50d8;
  color : white;
  padding: 15px;
}

.koort__input_label {
  position: absolute;
  font-size: 15px;
  top: 15px;
  left: 10px;
  cursor: text;
  transition: top .3s;
  padding: 0 5px;
}

.container_congratulations {
    text-align: center;
    padding-top: 30px;
}

.container_congratulations>h1 {
    font-weight: 700;
    font-size: 16px;
}

.container_congratulations>h2 {
    font-weight: 400;
    font-size: 15px;
}

.container_congratulations>h3{
    color: #2B50D8;
    font-size: 16px;
    font-weight: bold;
}

.tb-valores{
    width: calc(100% - 50px);
    margin-left: 50px;
}

.tb-valores thead{
    font-size: 14px;
    height: 30px;
}

.tb-valores > thead > tr > th {
    width: 50%;
    text-align: left;
}

.tb-valores > thead > tr > th:nth-child(2) {
    text-align: right !important;
}

.tb-valores tbody {
    font-size: 13px;
}

.tb-valores > tbody > tr > td {
    width: 50%;
    text-align: left;
}

.tb-valores > tbody > tr > td:nth-child(2) {
    text-align: right !important;
}

.tb-valores > tbody > tr > td.isRelease {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
}

.tb-valores > tbody > tr > strong.isRelease {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    display: block;
}

.tb-valores > thead > tr > strong {
  display: block;
  text-align: right;
}

.container-title-recommendation {
    width: 1100px;
    align-self: center;
    border-top: 1px solid #999;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.container-title-recommendation h2 {
    font-weight: bold;
    font-size: 20px;
}

.container-recommendation {
    align-self: center;
    width: 1100px;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-recommendation>h3 {
    width: 100%;
}

.container-recommendation>.card-recommendation {
    /* display: flex; */
    /* align-items: flex-start; */
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
}

.contact-form {
    height: calc(100% - 100px);
}

.contact-form>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-title-recommendation > h2 > strong {
  font-weight: inherit;
}

@media (max-width: 1160px) {
    .container-title-recommendation {
        width: 100%;
        padding: 50px 24px;
    }
    .container-title-recommendation h2 {
        font-weight: bold;
        font-size: 22px;
    }
    .container-recommendation {
        width: 100%;
        padding: 50px 24px;
    }
}

@media (max-width: 768px) {
    .container-title-recommendation {
        width: unset;
    }
    .container-title-recommendation h2 {
        font-weight: bold;
        font-size: 22px;
    }
    .container-recommendation {
        width: unset;
    }
}

:root {
    --cor-1: #FFA500;
}

*, *:before, *:after {
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif !important;
}

button:focus, button.focus {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

body {
    background: white;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 50px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: 400;
    align-self: flex-start;
}

.container__global {
    max-width: 1200px;
    margin-bottom: 250px;
    padding: 20px;
    background: white;
    border-radius: 15px;
}

.koort__input:focus~label, .koort__input:not(:placeholder-shown)~label {
    top: 5px;
    left: 10px;
    font-size: 12px;
    visibility: visible;
    color: #999;
}

.koort__input_control__diabled {
    background: rgba(0, 0, 0, 0.1);
}

.koort__input_control__diabled>.koort__input {
    background: unset;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.form-control:focus, .form-control:active:focus, .form-control.active:focus, .form-control.focus, .form-control:active.focus, .form-control.active.focus, .form-select:focus, .form-select:active:focus, .form-select.active:focus, .form-select.focus, .form-select:active.focus, .form-select.active.focus, .form-check-input:focus {
    outline: none!important;
    box-shadow: none;
    border: solid 1px #83786f;
    border-radius: 1px;
}

.form-control, .form-select, .form-check-input {
    border: solid 1px #83786f;
    border-radius: 1px;
}


.bt-global{
    padding: 0px;
    height: 50px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.bt-global_2{
    padding: 0px;
    height: 60px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.container-select-product {
    width: 100%;
    margin-bottom: 15px;
}

.select-product {
    margin-left: calc(50% - 175px);
    width: 350px;
    padding: 15px;
    outline: 0px;
    font-size: 15px;
    border: 1px solid #000;
}

.container-tb {
    width: 100%;
}

.container-tb span {
    font-weight: bold;
    font-size: 14px;
}

.container-tb>table {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb tr {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb>table td {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.container-tb>table tr td:nth-child(2) {
    background: #2b50d8;
    width: 30%;
    text-align: center;
    color: white;
    padding-right: 15px;
}

.container-value {
    width: 30%;
    background: #2b50d8;
    color: white;
    margin-left: 70%;
    height: 170px;
    text-align: center;
    padding-top: 30px;
    margin-top: 50px;
}

.container-value h2 {
    font-size: 25px;
}

.container-value h1 {
    font-size: 40px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .container-value {
        display: none;
    }
}


.container_fluid {
    margin-top: 5%;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.h4_tittle_2 {
    font-size: 16px;
    margin-bottom: 3%;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.text_p_1 {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: unset;
    line-height: unset;
}

.text_p_2 {
    font-size: unset;
    line-height: unset;
}

@media(max-width: 500px) {
    .col_sm_7 {
        padding-top: 10%;
    }
}

.col_sm_8 {
    padding-top: 2%;
}

.col_sm_8 span {
    padding-top: 10%;
    font-size: 16px;
    font-weight: 400;
    font-weight: 600;
    color: #50575a;
    text-decoration: none;
    position: relative;
}

.col_sm_9 span {
    padding-top: 10%;
    font-size: 16px;
    font-weight: 400;
    font-weight: 600;
    color: #50575a;
    text-decoration: none;
    position: relative;
}

.input_focus:focus {
    border-color: #cccccc;
    outline: 0;
    box-shadow: none;
}

.row_padding {
    padding-top: 2%;
    padding-bottom: 2%;
}

.span_style {
    text-align: inherit;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
    font-size: 14px;
    padding: 24px 16px;
    text-transform: uppercase;
}

.col_md_5_xs_6 p {
    text-align: inherit;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    color: #50575a;
}

.bt_global_3 {
    padding: 0px;
    height: 45px;
    width: 70%;
    border: 0;
    background-color: #dc3545;
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: match-parent;
    text-transform: uppercase;
}

@media(max-width: 500px) {
    .bt_global_3 {
        width: 100%;
    }
}

.col_xs_12_md_12 a {
    font-size: 12px;
    color: #50575a;
}

.h4_2 {
    padding-top: 4%;
    font-size: 24px;
    font-weight: 600;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
}

.col_md_8_xs_12 {
    padding-top: -10px;
}

.a_small {
    color: grey;
    font-size: 80%;
    padding-top: -1px;
}

.col_CRM Form.Label {
    margin-bottom: 2px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    align-content: flex-end;
}

.Col_md_8_end {
    min-height: 40px;
    padding: 8px 0;
    scroll-behavior: smooth;
}

.Col_md_8_end th {
    text-align: inherit;
    color: #50575a;
    font-size: 13px;
    padding: 24px 16px;
}

.nav_ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin-top: 30px;
    float: left;
    margin-bottom: 10px;
}

.nav_ul li {
    cursor: pointer;
    width: 20%;
    text-align: center;
    float: left;
    padding: 15px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-weight: 700;
    font-size: 14px;
}

.nav_ul li a {
    text-decoration: none !important;
    color: black;
}

.active_menu {
    text-decoration: none !important;
    color: black;
    border-bottom: 3px solid rgb(138, 204, 44) !important;
}


@media (max-width: 768px) {
    .nav_ul li {
        width: 100%;
        display: none;
    }

    .nav_ul li:nth-child(1) {
        display: unset;
    }
}


.ftBDaY {
    background-color: rgb(138, 204, 44);
    height: 5px;
    border-radius: 1px;
    align-self: center;
    margin-bottom: -3px;
    width: 100%;
    max-width: 220px;
}

.a_link {
    text-decoration: none;
    color: black;
}

.a_link:visited {
    color: black;
}

.a_link:visited {
    color: black;
}

@media (max-width: 480px) {
    .btn_1 {
        margin-top: 10px;
    }
}

@media(max-width: 500px) {
    .col_button {
        padding-top: 5%;
    }
}

.col_email_status {
    padding-top: 1%;
}

@media(max-width: 500px) {
    .col_email_status {
        padding-top: 10%;
    }
}

.col_leads_enviados {
    padding-top: 4%;
    margin-bottom: 2%;
}

@media(max-width: 500px) {
    .col_leads_enviados {
        padding-top: 10%;
    }
}

@media(max-width: 500px) {
    .col_leads_enviados {
        padding-top: 10%;
    }
}

@media (min-width: 768px) and (max-width: 875px) {
  .container-md, .container-sm, .container {
    max-width: 540px !important;
  }

  .bt-global_2 {
    width: 160px !important;
  }
}

.input-file-profile{
  width: 100%;
  background: #2b50d8;
  color : white;
  padding: 15px;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.text_p_2 {
    margin-top: 2%;
    font-size: unset;
    line-height: unset;
}
.container_fluid {
    margin-top: 5%;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.text_p_2 {
    margin-top: 2%;
    font-size: unset;
    line-height: unset;
}

.div_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(208, 208, 208);
    border-radius: 4px;
    padding: 24px;
    font-size: 14px;
    margin-bottom: 16px;
}

.div1 {
    border-style: solid;
    border-color: black;
    border-image: initial;
    border-width: 1px 0px;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    align-self: center;
    justify-content: center;
}

.container_h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
}

.a_link {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgb(43 80 216);
    font-size: inherit;
    text-decoration: none;
}

.a_link:hover {
    color: rgb(43 80 216);
}

.h3_1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 24px 0px;
}

.div1 {
    border-bottom: 3px solid #ebebeb;
    /* -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); */
}

.bt-global_2 {
    padding: 0px;
    height: 60px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.col_md_8_xs_12 {
    padding-top: -10px;
}

.a_small {
    color: grey;
    font-size: 80%;
    padding-top: -1px;
}

.col_CRM Form.Label {
    margin-bottom: 2px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    align-content: flex-end;
}

@media screen and (max-width: 720px) {
    .btn-global_2 {
        overflow-x: scroll;
    }
}

.Col_md_8_end {
    min-height: 40px;
    scroll-behavior: smooth;
}

.Col_md_8_end th {
    color: #50575a;
    font-size: 13px;
    padding: 24px 16px;
}

.div_th_2 th {
    margin-right: 5%;
}


.a_link {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgb(43 80 216);
    font-size: inherit;
    text-decoration: none;
}

.a_link:hover {
    color: rgb(43 80 216);
}

@media(max-width: 500px){
    .a_link{
        font-size: 10px;
    }
    
}
.container-modal {
    padding: 40px 50px 40px 50px;
}

.txt-modal {
    color: #FFF;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 17px;
    /* letter-spacing: 1.2px; */
    font-weight: 500;
    /* font-weight: bold; */
}

.txt-koort {
    color: #ffc000;
}

.container-preco {
    border: 4px solid #284adc;
    background: #284adc;
    height: 350px;
    width: 100%;
    margin-top: 10px;
}

.preco {
    background: #000;
    height: 200px;
    width: 100%;
}

.lb-preco {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 19px;
    letter-spacing: 1.5px;
    color: #fff;
    margin-top: 50px;
}

.txt-preco {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 40px;
    color: #fff;
    margin-top: 10px;
    font-weight: bold;
}

.container-preco-sugerido {
    float: left;
    width: 100%;
}

.preco-sugerido {
    width: 30%;
    float: left;
    height: 100px;
}
.preco-sugerido:nth-of-type(1) {
    padding-left: 25px;
}


.preco-sugerido:nth-of-type(3) {
    padding-right: 25px;
}

.preco-sugerido:nth-of-type(2) {
    width: 40%;
    border: 1.5px solid #ffc000;
    padding: 10px;
    height: 70px;
    margin-top: 40px;
}

.preco-sugerido:nth-of-type(2)>span {
    color: #fff;
    float: left;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
}

.logo {
    float: left;
    width: 23px;
    margin-right: 5px;
}

.logo-txt {
    float: left;
    width: 130px;
}

.title_box {
    margin-top: 65px;
    border-top: #ffc000 1px solid;
}

.title_box #title {
    position: relative;
    top: -0.9em;
    display: inline;
    color: #FFF;
    padding-right: 5px;
    background-color: #284adc;
}

.title_box_max #title {
    margin-left: 80px;
    padding-left: 5px;
}




.title_box #content {
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    /* float:left; */
    margin-top: -15px;
}

.title_box_max #content {
    text-align: right;
}

.container-txt-check{
    float: left;
    font-size: 15px;
    color: #FFF;
    margin-left: 10px;
    margin-top: -2px;
}

.container-txt-check > b{
    color: #ffc000;
}
.container-txt-check > span{
    font-weight: bold;
}

.container-button{
    width: 100%;
    text-align: right;
    margin-top: 40px;
    align-self: rigth;
    padding-left: 350px;
}


@media (max-width: 768px) {
    .txt-preco{
        font-size: 23px;
    }
    .container-button{
        padding-left: 60px;
    }
    .preco-sugerido:nth-of-type(1) {
        padding-left: 0px;
    }
    .title_box #title {
        font-size: 10px;
        top: -1.5em !important;
    }
    .title_box_max #title{
        margin-left: 29px;
    }
    .title_box #content{
        font-size: 9px;
    }
    .preco-sugerido:nth-of-type(2)>span{
        font-size: 9px;
    }
    .logo{
        margin-left: 20px;
        margin-bottom: 5px;
        margin-top: -5px;
    }
}
.Typist {
  min-height: 102px;
}

.bg-global {
  box-shadow: inset 0px 380px 180px -10px rgb(0 0 0 / 70%);
}

.bg-global::after {
  box-shadow: inset 0px 125px 60px -10px rgb(0 0 0 / 70%);
  content: " ";
  height: 200px;
  width: 100%;
  position: absolute;
}

.container_congratulations {
    text-align: center;
    padding-top: 30px;
}

.container_congratulations>h1 {
    font-weight: 700;
    font-size: 16px;
}

.container_congratulations>h2 {
    font-weight: 400;
    font-size: 15px;
}

.container_congratulations>h3{
    color: #2B50D8;
    font-size: 16px;
    font-weight: bold;
}
.input-file-profile{
  width: 100%;
  background: #2b50d8;
  color : white;
  padding: 15px;
}

.form-control, .form-label {
  font-size: 15px;
}

.koort__cookies {
  position: fixed;
  width: 100%;
  bottom: 10px;
  z-index: 9999999;

  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.koort__cookies_content {
  max-width: 900px;
  background-color: #4A4A4A;

  display: flex;
  padding: 14px;
  color: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  align-items: center;
}

.koort__cookies_content > label {
  font-size: 15px;
}

.koort__cookies_content > button{
  background: #8ACC2C;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
  margin-left: 15px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .koort__cookies_content {
   flex-direction: column;
   text-align: center;
  }

  .koort__cookies_content > label {
    max-width: 570px;
  }

  .koort__cookies_content > button {
    margin-left: 0;
    margin-top: 20px;
  }
}

.fullscreen {
  height: 100%;
}

.react-tag-input {
  border: solid 1px #83786f;
  min-height: 58px;
  border-radius: 0;
}

.react-tag-input__tag__content {
  text-transform: capitalize;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.form-control:focus, .form-control:active:focus, .form-control.active:focus, .form-control.focus, .form-control:active.focus, .form-control.active.focus, .form-select:focus, .form-select:active:focus, .form-select.active:focus, .form-select.focus, .form-select:active.focus, .form-select.active.focus {
    outline: none;
    box-shadow: none;
    border: solid 1px #2B50D8;
    border-radius: 1px;
}

.form-control, .form-select {
    border: solid 1px #AAB0B0;
    border-radius: 1px;
}

h1, h2, h3, h4, h5, h6 {
    font-size: unset;
    margin: unset;
    line-height: unset;
}

.form-label {
    color: #000;
}

iframe {
    min-height: 300px;
}

/* .react-joyride__tooltip button {
    color: black !important;
}

.react-joyride__tooltip > div > div {
    padding: 0px !important;

}

.react-joyride__tooltip button[data-action^=primary] {
    background-color: rgb(43, 80, 216) !important;
    color: white !important;
} */

/* .react-joyride__beacon{
    display: none !important;
} */


.dad-carousel .carousel__slider, .carousel__slider-tray-wrapper, .carousel__slider-tray--horizontal, .carousel__slider-tray--horizontal > li {
    height: 300px !important;
}

.dad-carousel .carousel__slider {
    width: 100% !important;
}

.dad-carousel .carousel__slider-tray--horizontal{
    width: 100% !important;

}

.dad-carousel .carousel__slider-tray--horizontal > div{
    height: 300px !important;
}

.bt-new-slide{
    position: absolute;
    background: 0,0,0,0;
}

.bt-new-slide-next{
    position: absolute;
    background: 0,0,0,0;
    right: 0px;
}

.react-slideshow-container{
    position: relative;
}


