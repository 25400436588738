.koort__cookies {
  position: fixed;
  width: 100%;
  bottom: 10px;
  z-index: 9999999;

  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.koort__cookies_content {
  max-width: 900px;
  background-color: #4A4A4A;

  display: flex;
  padding: 14px;
  color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  align-items: center;
}

.koort__cookies_content > label {
  font-size: 15px;
}

.koort__cookies_content > button{
  background: #8ACC2C;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
  margin-left: 15px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .koort__cookies_content {
   flex-direction: column;
   text-align: center;
  }

  .koort__cookies_content > label {
    max-width: 570px;
  }

  .koort__cookies_content > button {
    margin-left: 0;
    margin-top: 20px;
  }
}

.fullscreen {
  height: 100%;
}

.react-tag-input {
  border: solid 1px #83786f;
  min-height: 58px;
  border-radius: 0;
}

.react-tag-input__tag__content {
  text-transform: capitalize;
}
