input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.form-control:focus, .form-control:active:focus, .form-control.active:focus, .form-control.focus, .form-control:active.focus, .form-control.active.focus, .form-select:focus, .form-select:active:focus, .form-select.active:focus, .form-select.focus, .form-select:active.focus, .form-select.active.focus {
    outline: none;
    box-shadow: none;
    border: solid 1px #2B50D8;
    border-radius: 1px;
}

.form-control, .form-select {
    border: solid 1px #AAB0B0;
    border-radius: 1px;
}

h1, h2, h3, h4, h5, h6 {
    font-size: unset;
    margin: unset;
    line-height: unset;
}

.form-label {
    color: #000;
}

iframe {
    min-height: 300px;
}

/* .react-joyride__tooltip button {
    color: black !important;
}

.react-joyride__tooltip > div > div {
    padding: 0px !important;

}

.react-joyride__tooltip button[data-action^=primary] {
    background-color: rgb(43, 80, 216) !important;
    color: white !important;
} */

/* .react-joyride__beacon{
    display: none !important;
} */


.dad-carousel .carousel__slider, .carousel__slider-tray-wrapper, .carousel__slider-tray--horizontal, .carousel__slider-tray--horizontal > li {
    height: 300px !important;
}

.dad-carousel .carousel__slider {
    width: 100% !important;
}

.dad-carousel .carousel__slider-tray--horizontal{
    width: 100% !important;

}

.dad-carousel .carousel__slider-tray--horizontal > div{
    height: 300px !important;
}

.bt-new-slide{
    position: absolute;
    background: 0,0,0,0;
}

.bt-new-slide-next{
    position: absolute;
    background: 0,0,0,0;
    right: 0px;
}

.react-slideshow-container{
    position: relative;
}

