.bt-filter+label {
  height: 50px;
  padding-top: 7px;
  padding-bottom: 12px;
  padding-left: 7px;
  padding-right: 17px;
  width: 100%;
  height: 38px;
  font-size: 14px;
  background-color: white;
  border: solid 1px #83786f;
  border-radius: 1px;
  position: relative;
  background-position: right 0.3rem center;
  /* line-height: 50px; */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.bt-filter {
  display: none;
}

.bt-filter:checked+label {
  /* border: 1px solid #0d6efd; */
  color: #0d6efd;
}

.color-blue {
  /* border: 1px solid #0d6efd !important; */
  color: #0d6efd;
}

.color-blue>.icon-filter {
  color: #0d6efd;
}

.icon-filter {
  right: 0px;
}

.bt-filter:checked+label>.icon-filter {
  color: #0d6efd;
  transform: rotate(90deg);
}

.bt-filter:checked+label>.container__filter {
  visibility: visible;
}

.container__filter {
  position: absolute;
  top: 52px;
  left: -1px;
  /* border: 1px solid #83786f; */
  min-width: 370px;
  padding: 15px;
  background-color: white;
  /* border: solid 1px #83786f; */
  visibility: hidden;
  z-index: 99;
  -webkit-box-shadow: 0 4px 5px 4px rgb(0 0 0 / 25%);
  box-shadow: 0 4px 5px 4px rgb(0 0 0 / 25%);
}

.container__filter input {
  border: solid 1px #c7c7c7;
  height: 38px;
}

.no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-gutter>[class^="col-"] {
  padding-left: 0 !important;
  padding-right: 5px !important;
}
