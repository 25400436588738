.container-title-recommendation {
    width: 1100px;
    align-self: center;
    border-top: 1px solid #999;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.container-title-recommendation h2 {
    font-weight: bold;
    font-size: 20px;
}

.container-recommendation {
    align-self: center;
    width: 1100px;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-recommendation>h3 {
    width: 100%;
}

.container-recommendation>.card-recommendation {
    /* display: flex; */
    /* align-items: flex-start; */
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.contact-form {
    height: calc(100% - 100px);
}

.contact-form>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container-title-recommendation > h2 > strong {
  font-weight: inherit;
}

@media (max-width: 1160px) {
    .container-title-recommendation {
        width: 100%;
        padding: 50px 24px;
    }
    .container-title-recommendation h2 {
        font-weight: bold;
        font-size: 22px;
    }
    .container-recommendation {
        width: 100%;
        padding: 50px 24px;
    }
}

@media (max-width: 768px) {
    .container-title-recommendation {
        width: unset;
    }
    .container-title-recommendation h2 {
        font-weight: bold;
        font-size: 22px;
    }
    .container-recommendation {
        width: unset;
    }
}
