
.tb-valores{
    width: calc(100% - 50px);
    margin-left: 50px;
}

.tb-valores thead{
    font-size: 14px;
    height: 30px;
}

.tb-valores > thead > tr > th {
    width: 50%;
    text-align: left;
}

.tb-valores > thead > tr > th:nth-child(2) {
    text-align: right !important;
}

.tb-valores tbody {
    font-size: 13px;
}

.tb-valores > tbody > tr > td {
    width: 50%;
    text-align: left;
}

.tb-valores > tbody > tr > td:nth-child(2) {
    text-align: right !important;
}

.tb-valores > tbody > tr > td.isRelease {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
}

.tb-valores > tbody > tr > strong.isRelease {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    display: block;
}

.tb-valores > thead > tr > strong {
  display: block;
  text-align: right;
}
