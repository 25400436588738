.bg-global {
  box-shadow: inset 0px 380px 180px -10px rgb(0 0 0 / 70%);
}

.bg-global::after {
  box-shadow: inset 0px 125px 60px -10px rgb(0 0 0 / 70%);
  content: " ";
  height: 200px;
  width: 100%;
  position: absolute;
}
