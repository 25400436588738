@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --cor-1: #FFA500;
}

*, *:before, *:after {
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif !important;
}

button:focus, button.focus {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

body {
    background: white;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 50px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: 400;
    align-self: flex-start;
}

.container__global {
    max-width: 1200px;
    margin-bottom: 250px;
    padding: 20px;
    background: white;
    border-radius: 15px;
}

.koort__input:focus~label, .koort__input:not(:placeholder-shown)~label {
    top: 5px;
    left: 10px;
    font-size: 12px;
    visibility: visible;
    color: #999;
}

.koort__input_control__diabled {
    background: rgba(0, 0, 0, 0.1);
}

.koort__input_control__diabled>.koort__input {
    background: unset;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.form-control:focus, .form-control:active:focus, .form-control.active:focus, .form-control.focus, .form-control:active.focus, .form-control.active.focus, .form-select:focus, .form-select:active:focus, .form-select.active:focus, .form-select.focus, .form-select:active.focus, .form-select.active.focus, .form-check-input:focus {
    outline: none!important;
    box-shadow: none;
    border: solid 1px #83786f;
    border-radius: 1px;
}

.form-control, .form-select, .form-check-input {
    border: solid 1px #83786f;
    border-radius: 1px;
}


.bt-global{
    padding: 0px;
    height: 50px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.bt-global_2{
    padding: 0px;
    height: 60px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.container-select-product {
    width: 100%;
    margin-bottom: 15px;
}

.select-product {
    margin-left: calc(50% - 175px);
    width: 350px;
    padding: 15px;
    outline: 0px;
    font-size: 15px;
    border: 1px solid #000;
}

.container-tb {
    width: 100%;
}

.container-tb span {
    font-weight: bold;
    font-size: 14px;
}

.container-tb>table {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb tr {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.container-tb>table td {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.container-tb>table tr td:nth-child(2) {
    background: #2b50d8;
    width: 30%;
    text-align: center;
    color: white;
    padding-right: 15px;
}

.container-value {
    width: 30%;
    background: #2b50d8;
    color: white;
    margin-left: 70%;
    height: 170px;
    text-align: center;
    padding-top: 30px;
    margin-top: 50px;
}

.container-value h2 {
    font-size: 25px;
}

.container-value h1 {
    font-size: 40px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .container-value {
        display: none;
    }
}


.container_fluid {
    margin-top: 5%;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.h4_tittle_2 {
    font-size: 16px;
    margin-bottom: 3%;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.text_p_1 {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: unset;
    line-height: unset;
}

.text_p_2 {
    font-size: unset;
    line-height: unset;
}

@media(max-width: 500px) {
    .col_sm_7 {
        padding-top: 10%;
    }
}

.col_sm_8 {
    padding-top: 2%;
}

.col_sm_8 span {
    padding-top: 10%;
    font-size: 16px;
    font-weight: 400;
    font-weight: 600;
    color: #50575a;
    text-decoration: none;
    position: relative;
}

.col_sm_9 span {
    padding-top: 10%;
    font-size: 16px;
    font-weight: 400;
    font-weight: 600;
    color: #50575a;
    text-decoration: none;
    position: relative;
}

.input_focus:focus {
    border-color: #cccccc;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.row_padding {
    padding-top: 2%;
    padding-bottom: 2%;
}

.span_style {
    text-align: inherit;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
    font-size: 14px;
    padding: 24px 16px;
    text-transform: uppercase;
}

.col_md_5_xs_6 p {
    text-align: inherit;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    color: #50575a;
}

.bt_global_3 {
    padding: 0px;
    height: 45px;
    width: 70%;
    border: 0;
    background-color: #dc3545;
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: match-parent;
    text-transform: uppercase;
}

@media(max-width: 500px) {
    .bt_global_3 {
        width: 100%;
    }
}

.col_xs_12_md_12 a {
    font-size: 12px;
    color: #50575a;
}

.h4_2 {
    padding-top: 4%;
    font-size: 24px;
    font-weight: 600;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
}

.col_md_8_xs_12 {
    padding-top: -10px;
}

.a_small {
    color: grey;
    font-size: 80%;
    padding-top: -1px;
}

.col_CRM Form.Label {
    margin-bottom: 2px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    align-content: flex-end;
}

.Col_md_8_end {
    min-height: 40px;
    padding: 8px 0;
    scroll-behavior: smooth;
}

.Col_md_8_end th {
    text-align: inherit;
    color: #50575a;
    font-size: 13px;
    padding: 24px 16px;
}

.nav_ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin-top: 30px;
    float: left;
    margin-bottom: 10px;
}

.nav_ul li {
    cursor: pointer;
    width: 20%;
    text-align: center;
    float: left;
    padding: 15px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-weight: 700;
    font-size: 14px;
}

.nav_ul li a {
    text-decoration: none !important;
    color: black;
}

.active_menu {
    text-decoration: none !important;
    color: black;
    border-bottom: 3px solid rgb(138, 204, 44) !important;
}


@media (max-width: 768px) {
    .nav_ul li {
        width: 100%;
        display: none;
    }

    .nav_ul li:nth-child(1) {
        display: unset;
    }
}


.ftBDaY {
    background-color: rgb(138, 204, 44);
    height: 5px;
    border-radius: 1px;
    align-self: center;
    margin-bottom: -3px;
    width: 100%;
    max-width: 220px;
}

.a_link {
    text-decoration: none;
    color: black;
}

.a_link:visited {
    color: black;
}

.a_link:visited {
    color: black;
}

@media (max-width: 480px) {
    .btn_1 {
        margin-top: 10px;
    }
}

@media(max-width: 500px) {
    .col_button {
        padding-top: 5%;
    }
}

.col_email_status {
    padding-top: 1%;
}

@media(max-width: 500px) {
    .col_email_status {
        padding-top: 10%;
    }
}

.col_leads_enviados {
    padding-top: 4%;
    margin-bottom: 2%;
}

@media(max-width: 500px) {
    .col_leads_enviados {
        padding-top: 10%;
    }
}

@media(max-width: 500px) {
    .col_leads_enviados {
        padding-top: 10%;
    }
}

@media (min-width: 768px) and (max-width: 875px) {
  .container-md, .container-sm, .container {
    max-width: 540px !important;
  }

  .bt-global_2 {
    width: 160px !important;
  }
}
