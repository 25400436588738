.container_fluid {
    margin-top: 5%;
}

.h4_tittle {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
}

.text_p_2 {
    margin-top: 2%;
    font-size: unset;
    line-height: unset;
}

.div_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(208, 208, 208);
    border-radius: 4px;
    padding: 24px;
    font-size: 14px;
    margin-bottom: 16px;
}

.div1 {
    border-style: solid;
    border-color: black;
    border-image: initial;
    border-width: 1px 0px;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    align-self: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.container_h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: Nunito Sans;
    font-style: normal;
    color: #50575a;
}

.a_link {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgb(43 80 216);
    font-size: inherit;
    text-decoration: none;
}

.a_link:hover {
    color: rgb(43 80 216);
}

.h3_1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 24px 0px;
}

.div1 {
    border-bottom: 3px solid #ebebeb;
    /* -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); */
}

.bt-global_2 {
    padding: 0px;
    height: 60px;
    width: 100%;
    border: 0;
    background-color: rgb(43 80 216);
    color: white;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
}

.col_md_8_xs_12 {
    padding-top: -10px;
}

.a_small {
    color: grey;
    font-size: 80%;
    padding-top: -1px;
}

.col_CRM Form.Label {
    margin-bottom: 2px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    align-content: flex-end;
}

@media screen and (max-width: 720px) {
    .btn-global_2 {
        overflow-x: scroll;
    }
}

.Col_md_8_end {
    min-height: 40px;
    scroll-behavior: smooth;
}

.Col_md_8_end th {
    color: #50575a;
    font-size: 13px;
    padding: 24px 16px;
}

.div_th_2 th {
    margin-right: 5%;
}


.a_link {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgb(43 80 216);
    font-size: inherit;
    text-decoration: none;
}

.a_link:hover {
    color: rgb(43 80 216);
}

@media(max-width: 500px){
    .a_link{
        font-size: 10px;
    }
    
}